<template>
  <div>
    <div v-if="showEachEtiqueta">
      <div id="printPageContent">
        <slot name="printContent" />
      </div>
      <b-row>
        <b-col>
          <div v-if="showCalibrateButton" class="calibrate-button">
            <Button text="Calibrar" @click="calibrate" :loading="loading" />
          </div>
        </b-col>
        <b-col>
          <div class="printer-buttons">
            <Button :text="buttonName" @click="printContent" :loading="loading" />
          </div>
        </b-col>
      </b-row>
    </div>

    <Confirmacao
      v-else-if="isUserTag"
      action="impressão da etiqueta do usuário"
      fixedContent
      @confirmAction="printContent"
      @wants-exit="wantsExit"
      :buttonText="buttonName"
    >
      <template #fixedContent>
        <div>
          <b-row>
            <b-col>
              <span>
                <b>Registro:</b> {{ resultData.registro }}
              </span>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col>
              <span>
                <b>Nome:</b> {{ resultData.nome }}
              </span>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <b-col>
              <span>
                <b>Coren:</b> {{ resultData.coren ? resultData.coren : '-' }}
              </span>
            </b-col>
          </b-row>
        </div>
      </template>
    </Confirmacao>

    <Confirmacao
      v-else
      action="impressão de etiquetas"
      fixedContent
      @confirmAction="printContent"
      @wants-exit="wantsExit"
      :buttonText="buttonName"
    >
      <template #fixedContent>
        <div>
          <span>IDs: {{ idsLabel }}</span>
          <br />
          <b-row v-if="isMaterialTag">
            <b-col cols="8" class="cols-tag-count">
              <span>Total de etiquetas por material: </span>
            </b-col>
            <b-col cols="2" class="cols-tag-count">
              <b-form-input
                v-model="tagNumbers"
                min="1"
                max="5"
                type="range"
                name="tagNumbers"
                size="sm"
                number
              >
              </b-form-input>
            </b-col>
            <b-col>
              {{ tagNumbers }}
            </b-col>
          </b-row>
          <span>Total: {{ totalEtiquetas }} etiquetas</span>
        </div>
      </template>
    </Confirmacao>
  </div>
</template>

<script>
import logger from '@/services/logger';
import importScriptsZebra from '@/services/zebra';
import Confirmacao from './Confirmacao';
import Button from './Button';

export default {
  components: {
    Button,
    Confirmacao,
  },
  props: {
    buttonName: {
      type: String,
      default: 'Imprimir',
    },
    hideId: {
      type: String,
    },
    idTipoMaterial: {
      type: Number,
      default: null,
    },
    stringZebra: {
      type: String,
      default: '',
    },
    resultData: {
      type: Object,
      default: null,
    },
    indexMaterial: {
      type: Number,
      default: 0,
    },
    isMaterialTag: {
      type: Boolean,
      default: false,
    },
    isUserTag: {
      type: Boolean,
      default: false,
    },
    fixedContent: {
      type: Boolean,
      default: false,
    },
    showCalibrateButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      errorZpl: false,
      tagNumbers: 3,
    };
  },

  computed: {
    showEachEtiqueta() {
      return (
        this.fixedContent
        || this.errorZpl
        || this.indexMaterial > 0
        || (this.resultData
          && this.resultData.materiais
          && this.resultData.materiais.length === 1)
      );
    },
    idsSumGenericos() {
      const [ids, sumGenericos] = this.resultData.materiais.reduce((acc, mat) => {
        if (mat.id_material_generico) {
          return [acc[0], acc[1] + mat.qt_material_generico];
        }
        return [acc[0].concat(mat.shownId), acc[1]];
      }, [[], 0]);

      return { ids, sumGenericos };
    },
    idsLabel() {
      if (!this.idsSumGenericos.sumGenericos) {
        return this.idsSumGenericos.ids.join(', ');
      }
      if (!this.idsSumGenericos.ids || !this.idsSumGenericos.ids.length) {
        return `${this.idsSumGenericos.sumGenericos} materiais genéricos`;
      }
      return `${this.idsSumGenericos.ids.join(', ')}, além de ${
        this.idsSumGenericos.sumGenericos
      } materiais genéricos`;
    },
    totalEtiquetas() {
      if (this.isMaterialTag) {
        return this.idsSumGenericos.ids
          && this.idsSumGenericos.ids.length * this.tagNumbers;
      }
      const sumMateriais = this.idsSumGenericos.ids ? this.idsSumGenericos.ids.length : 0;
      return sumMateriais + this.idsSumGenericos.sumGenericos;
    },
  },

  mounted() {
    importScriptsZebra();
  },

  methods: {
    wantsExit() {
      this.$emit('wants-exit');
    },
    printContent() {
      if (this.resultData && this.resultData.directPrint) {
        this.printWindow();
      } else if (this.errorZpl || this.indexMaterial > 0) {
        this.printWindow();
      } else if (this.stringZebra) {
        this.printZpl(this.stringZebra);
      } else if (
        this.resultData
        && (
          this.resultData.materiais
          && this.resultData.materiais[0]
          && this.resultData.materiais[0].stringZebra
        )
      ) {
        const formatedZpl = this.formatZpl();
        this.printZpl(formatedZpl);
      } else {
        this.handleError('Não foi encontrado stringZebra');
      }
    },

    calibrate() {
      this.printZpl('~JC', true);
    },

    formatZpl() {
      // TODO Refatorar método
      if (this.isMaterialTag) {
        let zplScope = this.resultData.materiais[0].stringZebra;
        let zplToPrinter = '';
        let indexOfMaterial = 0;
        let indexOfLabels = 0;
        let counter = 0;
        let aux = '';

        while (this.resultData.materiais[indexOfMaterial]) {
          counter = 0;

          while (counter < this.tagNumbers) {
            if (indexOfLabels > 2) {
              indexOfLabels = 0;
              zplToPrinter += `${zplScope.replace('<%freeLabel%>', aux)}\n`;
              zplScope = this.resultData.materiais[indexOfMaterial].stringZebra;
              aux = '';
              aux += `${this.resultData.materiais[indexOfMaterial].isolatedLabel[indexOfLabels]}\n`;
              counter++;
              indexOfLabels++;
            } else {
              aux += `${this.resultData.materiais[indexOfMaterial].isolatedLabel[indexOfLabels]}\n`;
              counter++;
              indexOfLabels++;
            }
          }
          indexOfMaterial++;
        }

        zplToPrinter += `${zplScope.replace('<%freeLabel%>', aux)}\n`;

        return zplToPrinter;
      }

      return this.resultData.materiais.reduce(
        (acum, curr) => {
          if (curr.qt_material_generico) {
            return acum + curr.stringZebra.repeat(curr.qt_material_generico);
          }
          return acum + curr.stringZebra;
        },
        '',
      );
    },

    printWindow() {
      const elementToHide = document.getElementById(this.hideId);
      if (elementToHide) {
        elementToHide.style.display = 'none';
      }
      window.scrollTo(0, 0);
      window.print();
    },

    async printZpl(zpl, wantsCalibrate = false) {
      try {
        this.loading = true;

        this.printerJob(zpl, wantsCalibrate);
      } catch (e) {
        this.handleError(`Erro no catch do printZpl: ${e.message}`);
      } finally {
        this.loading = false;
      }
    },

    printerJob(zpl, wantsCalibrate, indexPrinter = 0) {
      // eslint-disable-next-line no-undef
      BrowserPrint.getLocalDevices(
        (deviceList) => {
          if (!deviceList || !deviceList.printer || !deviceList.printer[0]) {
            this.handleError('Não encontrou nenhuma impressora');
            return;
          }
          deviceList.printer[0].send(
            zpl,
            async () => {
              this.$emit('successSend');
              await swal({
                title: 'Sucesso!',
                text: wantsCalibrate ? 'Impressora calibrada com sucesso!'
                  : 'Etiqueta enviada à impressora com sucesso!',
                icon: 'success',
                button: 'OK',
              });
            },
            async (error) => {
              // Tratar o erro da forma correta
              if (deviceList[indexPrinter + 1]) {
                const result = await swal({
                  title: 'Ops!',
                  // eslint-disable-next-line max-len
                  text: 'Aparentemente a impressora atual não está imprimindo. Encontramos outra. Deseja imprimir nela?',
                  icon: 'warning',
                  buttons: { cancel: 'Não', confirm: 'Sim' },
                });
                if (result) {
                  this.printerJob(zpl, wantsCalibrate, indexPrinter + 1);
                }
                this.handleError(error);
              } else {
                this.handleError(error);
              }
            },
          );
        },
        () => {
          this.handleError('Erro no getLocalDevices', wantsCalibrate);
        },
      );
    },

    async handleError(error, wantsCalibrate) {
      this.errorZpl = true;
      this.$emit('show-xclose');
      logger('components/Utils/Printer printZpl', error);
      await swal({
        title: 'Erro',
        icon: 'error',
        text: wantsCalibrate ? 'Não foi possível calibrar a impressora. Favor realizar o processo manualmente'
          : `Tivemos um erro na comunicação automática com a impressora.
          Favor contactar o suporte.
          Em contingência, realize a impressão de cada etiqueta de formal individual.`,
        button: 'Continuar...',
      });
      if (
        (
          this.fixedContent
          || (this.resultData && this.resultData.materiais && this.resultData.materiais.length === 1)
        )
        && !wantsCalibrate
      ) {
        this.printWindow();
      }
    },
  },
};
</script>

<style>
  @media print {
    body * {
      visibility: hidden;
    }
    #printPageContent,
    #printPageContent * {
      visibility: visible;
    }
    #printPageContent {
      position: fixed;
      left: 0;
      top: 0;
    }
  }
</style>

<style scoped>
  .modal-printer-button {
    display: flex;
    justify-content: space-between;
  }
  .text-white {
    margin-right: 2rem;
  }
  .printer-buttons {
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }
  .div-many-labels {
    padding: 0 16px 24px 16px;
    color: #35384d;
  }
  .div-many-labels h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  .div-many-labels span {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
</style>
