<template>
  <div id="qrCode" class="text-center qrCode-div">
    <qrcode-vue
      :value="dadosEtiqueta.code"
      level="H"
      renderAs="svg"
      size="75"
      class="qr-code"
    ></qrcode-vue>
    <label class="qr-code-label mb-4 descricao-material">
      {{ dadosEtiqueta.registro + '-' + dadosEtiqueta.nome }}
    </label>
    <br />
    <qrcode-vue
      :value="dadosEtiqueta.code"
      level="H"
      renderAs="svg"
      size="75"
      class="qr-code printOnly"
    ></qrcode-vue>
    <label class="qr-code-label mb-4 descricao-material printOnly">
      {{ dadosEtiqueta.registro + '-' + dadosEtiqueta.nome }}
    </label>
    <br />
    <qrcode-vue
      :value="dadosEtiqueta.code"
      level="H"
      renderAs="svg"
      size="75"
      class="qr-code printOnly"
    ></qrcode-vue>
    <label class="qr-code-label mb-4 descricao-material printOnly">
      {{ dadosEtiqueta.registro + '-' + dadosEtiqueta.nome }}
    </label>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  components: {
    QrcodeVue,
  },
  props: {
    dadosEtiqueta: {
      type: Object,
      default: null,
      required: true,
    },
  },
};
</script>

<style>

*:focus {
  outline: 0 !important;
}

.qr-code-label {
  margin: auto;
  font-style: italic;
  color: #8f8f8f;
}

@media print {
  .qrCode-printer {
    height: 10.4cm !important;
    width: 2.85cm !important;
    padding: 0.2cm !important;
    padding-top: 0.5cm !important;
  }

  .qrCode-div {
    /* display: grid; */
    /* grid-template-rows: repeat(3, 300px 1fr); */
    margin-bottom: 2.3cm !important;
  }

  .qr-code {
    margin-left: 0.55cm !important;
    height: 2cm !important;
    width: 2.2cm !important;
  }

  .descricao-material {
    width: 100% !important;
    font-size: 0.3cm !important;
    height: 0.6cm !important;
    margin-top: 0cm !important;
    margin-left: 0.1cm !important;
    margin-bottom: 2.9cm !important;
  }

  .qrCode-INVCXCuuid {
    width: 100% !important;
    font-size: 0.26cm !important;
    height: 1.5cm !important;
    margin-left: 0.1cm !important;
  }

  .printOnly {
    display: inline-block !important;
  }
}
</style>
