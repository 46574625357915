<template>
  <div>
    <GenericTable
      ref="lista"
      name="usuário"
      title="usuários"
      add-route="usuarioAdicionar"
      edit-route="usuarioEditar"
      :cols="[
        'Nome',
        'Registro',
        'Coren',
        'Último Login',
        'Cargo',
        'Ativo',
        'Imprimir'
      ]"
      :cols-name="[
        'nome',
        'registro',
        'coren',
        'ultimo_login',
        'grupo',
        'ativo',
        'code'
      ]"
      :cols-map="
        i => [
          i.nome,
          i.registro,
          i.coren,
          moment(i.ultimo_login)
            .local()
            .format('DD/MM/YYYY HH:mm:ss'),
          i.grupo,
          i.ativo,
          {
            code: i.code,
            stringZebra: i.stringZebra
          }
        ]
      "
      :route="usuarioRoute"
      :permissionsToWrite="['rw_usuario']"
      :filters="filters"
      @clear-filters="clearFilters"
    >
      <template #Ativo="{ item }">
        <b-btn
          v-b-tooltip.hover.top="'Ativar/desativar usuário'"
          :variant="item.cols[5] ? 'success' : 'danger'"
          class="py-2 rounded"
          @click="toggleActive(item)"
          :disabled="item.busy"
        >
          <b-spinner v-if="item.busy" label="Aguarde" small />
          <check-icon v-else-if="item.cols[5]" class="w20px" />
          <x-icon v-else class="w20px" />
        </b-btn>
      </template>

      <template #Imprimir="{ item }">
        <button
          type="button"
          @click="setModalQrCodeUsuario(item)"
          class="icon-printer btn btn-sm btn-outline-light text--black"
        >
          <img src="../../assets/img/printer.svg" />
        </button>
      </template>

      <template #filters>
       <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Área</label>
              <GenericSelect
                name="area"
                labelKey="descricao"
                v-model="filters.id_area"
                route="area"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Coren</label>
              <b-form-input
                v-model="filters.coren"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Nome</label>
              <b-form-input
                v-model="filters.nome"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Registro</label>
              <b-form-input
                v-model="filters.registro"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Unidade</label>
              <GenericSelect
              name="unidade"
              labelKey="nome"
              v-model="filters.id_unidade"
              route="unidade"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template #botoes>
        <b-button
          class="primary-contained-button mr-2 align-self-center"
          variant="none"
          @click="abrirModalGrupoUsuario"
          >Gerenciar cargos</b-button
        >
        <router-link
          :to="{ name: 'usuarioAdicionar' }"
          class="btn secondary-contained-button text-white align-self-center"
          data-cy="Adicionar usuário"
          >Adicionar usuário</router-link
        >
        <b-button
          @click="toggleFilters"
          variant="none"
          class="btn toggle-button align-self-center"
          ><SlidersIcon
        /></b-button>
      </template>
    </GenericTable>

    <b-modal
      size="lg"
      id="modalGrupoUsuario"
      ref="modalGrupoUsuario"
      title="Gerenciar cargos"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <GrupoUsuario
        ref="cadGrupos"
        @ok="fecharModalGrupoUsuario"
      ></GrupoUsuario>
    </b-modal>
    <Modal
      id="modalEtiqueta"
      ref="modalEtiqueta"
      name="modalEtiqueta"
      key="modalEtiqueta"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @hidden="closingModal"
      :hideXClose="handlerXButton"
    >
      <Printer
        :resultData="dadosEtiqueta"
        :isUserTag="true"
        buttonName="IMPRIMIR"
        hideSection="section"
        hideContent="content"
        :stringZebra="stringZebra"
        @successSend="successZPL"
        @wants-exit="wantsExit"
        @show-xclose="handleXButton"
      >
        <template #printContent class="w">
          <Etiqueta
            :dadosEtiqueta="dadosEtiqueta"
          />
        </template>
      </Printer>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment';

import RestResourceUsuario from '@/services/usuario';

import GrupoUsuario from '@/views/GrupoUsuario';
import Modal from '@/components/Utils/Modal';
import Printer from '../../components/Utils/Printer';
import Etiqueta from './Etiqueta';
// import unidadeServices from '../../services/unidade';
import GenericSelect from '../../components/Form/GenericSelect';

export default {
  components: {
    GrupoUsuario,
    Modal,
    Printer,
    Etiqueta,
    GenericSelect,
  },

  data() {
    return {
      usuarioRoute: 'usuario',
      filters: {
        registro: '',
        coren: '',
        nome: '',
        id_area: null,
        // id_unidade: null,
      },
      // optionsUnidade: [
      //   { value: -1, text: 'Rede' },
      // ],
      optionsArea: [
        {},
      ],
      dadosEtiqueta: {
        registro: null,
        nome: '',
        code: '',
      },
      stringZebra: '',
      zplSuccessPrinter: false,
      handlerXButton: true,
    };
  },
  async mounted() {
    // const unidades = await unidadeServices.getUnidades();
    // this.optionsUnidade = this.optionsUnidade.concat(unidades.map((unid) => ({
    //   value: unid.id_unidade,
    //   text: unid.nome,
    // })));

    this.optionsArea = [];
  },
  methods: {
    clearFilters() {
      this.filters.coren = '';
      this.filters.nome = '';
      this.filters.registro = '';
      this.filters.id_area = '';
      this.filters.id_unidade = '';
    },
    setModalQrCodeUsuario(linha) {
      [
        this.dadosEtiqueta.nome,
        this.dadosEtiqueta.registro, , , , , ,
      ] = linha.cols;
      this.dadosEtiqueta.code = linha.cols[6].code;
      this.stringZebra = linha.cols[6].stringZebra;
      this.$refs.modalEtiqueta.show();
    },
    async toggleActive(user) {
      if (typeof user.busy === 'undefined') {
        this.$set(user, 'busy', false);
      }
      user.busy = true;

      try {
        await RestResourceUsuario.updateActive(user.id, !user.cols[5]);

        this.$refs.lista.update();
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          title: 'Oops!', // e.response.status,
          text: errorMessage || 'Ocorreu um problema ao atualizar o registro',
          icon: 'error',
        });
      }
      user.busy = false;
    },

    toggleFilters() {
      this.$refs.lista.toggleFilters();
    },

    abrirModalGrupoUsuario() {
      this.$refs.modalGrupoUsuario.show();
    },

    fecharModalGrupoUsuario() {
      this.$refs.modalGrupoUsuario.hide();
    },

    moment(date) {
      return moment(date);
    },

    successZPL() {
      this.zplSuccessPrinter = true;
      this.$refs.modalEtiqueta.hide();
    },
    closingModal() {
      this.zplSuccessPrinter = false;
      this.handlerXButton = true;
      this.$refs.modalEtiqueta.hide();
    },
    wantsExit() {
      this.$refs.modalEtiqueta.hide();
    },
    handleXButton() {
      this.handlerXButton = !this.handlerXButton;
    },
  },
};
</script>

<style>
.icon-printer {
  border-color: #5e627a;
}

*:focus {
  outline: 0 !important;
}

.qr-code-label {
  margin: auto;
  font-style: italic;
  color: #8f8f8f;
}

@media print {
  .qrCode-printer {
    height: 10.4cm !important;
    width: 2.85cm !important;
    padding: 0.2cm !important;
    padding-top: 0.5cm !important;
  }

  .qrCode-div {
    /* display: grid; */
    /* grid-template-rows: repeat(3, 300px 1fr); */
    margin-bottom: 2.3cm !important;
  }

  .qr-code {
    margin-left: 0.55cm !important;
    height: 2cm !important;
    width: 2.2cm !important;
  }

  .descricao-material {
    width: 100% !important;
    font-size: 0.3cm !important;
    height: 0.6cm !important;
    margin-top: 0cm !important;
    margin-left: 0.1cm !important;
    margin-bottom: 2.9cm !important;
  }

  .qrCode-INVCXCuuid {
    width: 100% !important;
    font-size: 0.26cm !important;
    height: 1.5cm !important;
    margin-left: 0.1cm !important;
  }

  .printOnly {
    display: inline-block !important;
  }
}
</style>
